import { useEffect, useState } from "react";

import { Box, Button, Chip, Typography } from "@mui/material";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import { generateOrderBy } from "shared/components/CollapsibleTable/utils";
import DataGridTable from "shared/components/DataGridTable/DataGridTable";
import {
  createStyledLinkCell,
  renderTagsCell,
  renderVehicleImageCell,
  useDataGridSortHandler,
} from "shared/components/DataGridTable/utils";
import ErrorMessage from "shared/components/ErrorMessage";
import TextWithTooltip from "shared/components/tooltips/TextWithTooltip";
import {
  GetOpenRequestsQuery,
  Order_By,
  useGetOpenRequestsLazyQuery,
  UsersFleetVehicles_Bool_Exp,
} from "shared/generated/graphql";
import { prettyMonthDayYear } from "shared/lib/dates";
import { getPrettyString } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../backend/common/workflow.types";
import { clearServiceStorage } from "../../providers/AccountSettings/storageUtils";
import { FleetIdQueryParam, useFleets } from "../../providers/FleetProvider";
import { useServices } from "../../providers/ServiceFlowProvider";
import {
  ctaStatuses,
  RequestStatusDisplayVehicleText,
  SaasRequestStatusDisplayVehicleText,
} from "../../services/request.service";
import { VehicleTagAutoComplete } from "../VehicleTagAutocomplete";

type RequestTableType = GetOpenRequestsQuery["requests"][0];

function renderStatusCell(
  params: GridRenderCellParams<{
    status: {
      requestId: RequestTableType["id"];
      workflowType: RequestTableType["workflowType"];
      requestStatus: RequestTableType["status"];
      currentFleetId: FleetIdQueryParam;
    };
  }>
) {
  const isSU360 = params.value.workflowType === WorkflowType.ServiceUp;
  const statusText = isSU360
    ? RequestStatusDisplayVehicleText.get(params.value.requestStatus ?? "")
    : SaasRequestStatusDisplayVehicleText.get(params.value.requestStatus ?? "");

  if (ctaStatuses?.includes(params.value.requestStatus)) {
    return (
      <Button
        variant="contained"
        size="small"
        href={`request/${params.value.requestId}?fleetId=${params.value.currentFleetId}`}
      >
        <TextWithTooltip text={statusText} variant="inherit" />
      </Button>
    );
  }

  return <Chip variant="outlined" size="small" label={statusText} />;
}

const columns: GridColDef[] = [
  {
    field: "Image",
    headerName: "",
    sortable: false,
    filterable: false,
    disableExport: true,
    renderCell: renderVehicleImageCell,
  },
  {
    field: "id",
    headerName: "Order",
    hideable: false,
    filterable: false,
    renderCell: (params) => {
      const LinkCell = createStyledLinkCell(
        (params) =>
          `request/${params.value}?fleetId=${params.row.currentFleetId}`
      );
      return (
        <UnreadMessageBadge
          content={LinkCell(params)}
          unreadMessageCount={params.row.unreadMessageCount}
        />
      );
    },
  },
  { field: "shop.name", headerName: "Shop", filterable: false },
  { field: "fleet.name", headerName: "Fleet", filterable: false },
  {
    field: "usersFleetVehicles.carNickname",
    headerName: "Name",
    filterable: false,
  },
  { field: "vehicle.year", headerName: "YMM", filterable: false },
  { field: "vehicle.plate", headerName: "License Plate", filterable: false },
  { field: "vehicle.vin", headerName: "VIN", filterable: false },
  { field: "poNumber", headerName: "PO#", filterable: false },
  { field: "createdAt", headerName: "Requested Date", filterable: false },
  { field: "customerECD", headerName: "ECD", filterable: false },
  {
    field: "tagData",
    headerName: "Tags",
    renderCell: renderTagsCell,
    sortable: false,
    disableExport: true,
    filterOperators: [
      {
        label: "contains",
        value: "in",
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) return null;
          return ({ value }) => value === filterItem.value;
        },
        InputComponent: (props) => <VehicleTagAutoComplete {...props} />,
      },
    ],
  },
  {
    field: "status",
    headerName: "Status",
    filterable: false,
    minWidth: 170,
    renderCell: renderStatusCell,
  },
];

function getSearchFilters(
  search: string,
  fleetIds: number[],
  selectedFleetTags: number[]
) {
  const fleetIdFilter =
    fleetIds.length > 1 ? { _in: fleetIds } : { _eq: fleetIds[0] };

  const baseFilters = [{ deletedAt: { _is_null: true } }];

  const vehicleTagsFilter = {
    vehicleTags: { tagId: { _in: selectedFleetTags } },
  };

  const orData: UsersFleetVehicles_Bool_Exp[] = [
    { carNickname: { _ilike: `%${search}%` } },
    { year: { _ilike: `%${search}%` } },
    { make: { _ilike: `%${search}%` } },
    { model: { _ilike: `%${search}%` } },
    { plate: { _ilike: `%${search}%` } },
    { vin: { _ilike: `%${search}%` } },
    { requests: { services: { description: { _ilike: `%${search}%` } } } },
    { requests: { poNumber: { _ilike: `%${search}%` } } },
  ];

  if (search && !isNaN(+search)) {
    orData.push({ requests: { id: { _eq: parseInt(search, 10) } } });
  }

  return {
    fleetId: fleetIdFilter,
    _and: [
      ...baseFilters,
      search?.length ? { _or: orData } : {},
      selectedFleetTags.length > 0 ? vehicleTagsFilter : {},
    ],
  };
}

export const DataGridOrdersPage = () => {
  const tableApiRef = useGridApiRef();
  const { getUnreadMessagesCount, setRequestIds } = useUnreadRequests();
  const { currentFleetIds, currentFleetId } = useFleets();
  const { clearServices } = useServices();
  const { sortField, sortDirection, handleSortModelChange } =
    useDataGridSortHandler({
      field: "id",
      direction: Order_By.Desc,
    });

  const [filterModel, setFilterModel] = useState<UsersFleetVehicles_Bool_Exp>(
    {}
  );
  const [getRequests, { loading, data, error }] = useGetOpenRequestsLazyQuery();

  useEffect(() => {
    tableApiRef.current?.setFilterModel({ items: [] }); // clear filters when fleet changes
  }, [currentFleetIds, tableApiRef]);

  useEffect(() => {
    clearServices();
    clearServiceStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchRequestData() {
      await getRequests({
        fetchPolicy: "network-only",
        variables: {
          fleetIds: currentFleetIds,
          orderBy: generateOrderBy(sortField, sortDirection),
          filter: filterModel,
        },
      });

      const allRequestIds = data?.requests.flatMap(
        (request) => request.id ?? []
      );
      if (!allRequestIds) return;
      setRequestIds(allRequestIds);
    }
    if (!currentFleetIds?.length) return;
    fetchRequestData();
  }, [
    currentFleetIds,
    sortField,
    sortDirection,
    filterModel,
    getRequests,
    data?.requests,
    setRequestIds,
  ]);

  const rows =
    data?.requests.map((request) => {
      const vehicle = request.usersFleetVehicles?.vehicle;
      return {
        Image: {
          vehicleId: request.usersFleetVehicles?.vehicleId,
          imageUrl: request.usersFleetVehicles?.imageUrl,
        },
        id: request.id,
        currentFleetId: currentFleetId,
        unreadMessageCount: getUnreadMessagesCount(request.id),
        "shop.name":
          request.workflowType === WorkflowType.SAAS
            ? request.shop?.name
            : "ServiceUp",
        "fleet.name": request.fleet?.name,
        "usersFleetVehicles.carNickname":
          request.usersFleetVehicles?.carNickname,
        "vehicle.year": getPrettyString([
          vehicle?.year,
          vehicle?.make,
          vehicle?.model,
        ]),
        "vehicle.plate": vehicle?.plate,
        "vehicle.vin": vehicle?.vin,
        createdAt: prettyMonthDayYear(request?.createdAt),
        customerECD: prettyMonthDayYear(request?.customerECD, true),
        poNumber: request.poNumber,
        status: {
          requestId: request.id,
          requestStatus: request.status,
          workflowType: request.workflowType,
          currentFleetId: currentFleetId,
        },
        tagData: {
          tags: request.usersFleetVehicles?.vehicleTags.map((tag) => tag.tag),
        },
      };
    }) ?? [];

  const handleFilterModelChange = (gridFilterModel: GridFilterModel) => {
    if (!currentFleetIds) return;
    const searchTerm = gridFilterModel?.quickFilterValues?.[0];

    let tagsFilter = [];
    if (gridFilterModel.items.length > 0) {
      tagsFilter = gridFilterModel.items[0].value;
    }

    const updatedQuickFilter = getSearchFilters(
      searchTerm,
      currentFleetIds,
      tagsFilter
    );

    setFilterModel(updatedQuickFilter);
  };

  const tableId = "fleets-orders";
  return (
    <Box p={3} mb={4} mt={2}>
      <Typography variant="h6" mb={2}>
        Orders
      </Typography>

      {!error ? (
        <DataGridTable
          id={tableId}
          apiRef={tableApiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          handleSortChange={handleSortModelChange}
          toolbarFilter={{
            handleFilterChange: handleFilterModelChange,
            enableColumnFilter: true,
            singleFilter: true,
          }}
          pinnedColumns={{ right: ["status"] }}
          defaultMobileColumns={[
            "id",
            "usersFleetVehicles.carNickname",
            "status",
          ]}
        />
      ) : (
        <ErrorMessage resourceName="orders" />
      )}
    </Box>
  );
};
