import AddIcon from "@mui/icons-material/Add";
import {
  alpha,
  Box,
  ButtonBase,
  Divider,
  Grid,
  Stack,
  Tab,
  Table,
  TableBody,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";

import SearchBar from "shared/components/table/SearchBar";
import TableHeadWithSort from "shared/components/table/TableHeadWithSort";
import { TableHeader } from "shared/components/table/TableHeadWithSort/types";
import useRouterSort from "shared/components/table/TableHeadWithSort/useRouterSort";
import { useGetFleetQuery } from "shared/generated/graphql";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";
import { Colors } from "shared/theme/colors";

import { useFleets } from "../../../providers/FleetProvider";
import NeedHelpContactSupportText from "../../NeedHelpContactSupportText";

import InsuranceRequestTableRow from "./InsuranceRequestTableRow";
import useInsuranceRequests, {
  DEFAULT_ORDER_FIELD,
} from "./useInsuranceRequests";

const headers: TableHeader[] = [
  {
    label: "Order",
    isSortable: true,
    fieldName: "requestId",
  },
  {
    label: "Claim #",
    isSortable: true,
    fieldName: "claimNumber",
  },
  {
    label: "Request Date",
    isSortable: true,
    fieldName: "createdAt",
  },
  {
    label: "Insured",
    isSortable: true,
    fieldName: "policyHolderFirstName",
  },
  {
    label: "Policy #",
    isSortable: true,
    fieldName: "policyNumber",
  },
  {
    label: "Phone #",
    isSortable: true,
    fieldName: "phoneNumber",
  },
  {
    label: "Email",
    isSortable: true,
    fieldName: "email",
  },
  {
    label: "",
    isSortable: false,
    fieldName: "cta",
  },
];

const AddClaimLink = ({
  fleetId,
  label,
  theme,
}: {
  fleetId: number;
  label: string;
  theme: Theme;
}) => {
  return (
    <Link href={`/claims/new?fleetId=${fleetId}`} legacyBehavior>
      <ButtonBase data-testid={"addClaimButton"}>
        <Box
          py={1}
          px={2}
          border={1}
          borderRadius={1}
          borderColor={Colors.Grey}
          alignContent={"center"}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.03),
            },
          }}
        >
          <Typography
            fontWeight={500}
            color={theme.palette.primary.main}
            mr={1}
            whiteSpace={"nowrap"}
          >
            {label}
          </Typography>
          <AddIcon fontSize="small" />
        </Box>
      </ButtonBase>
    </Link>
  );
};

const InsuranceRequests = () => {
  const { currentFleetIdAsNumber, isValidFleetId } = useFleets();
  const router = useRouter();
  const theme = useTheme();
  const { sortField, sortDirection, updateSort } = useRouterSort({
    defaultField: DEFAULT_ORDER_FIELD,
  });

  const { data: fleetData } = useGetFleetQuery({
    variables: {
      fleetId: currentFleetIdAsNumber,
    },
    skip: !isValidFleetId,
  });
  const fleet = fleetData?.fleets_by_pk;
  const fleetName = fleet?.name;

  const {
    openInsuranceRequests,
    error,
    loading,
    insuranceRequestSearch,
    updateInsuranceRequestsSearch,
  } = useInsuranceRequests();

  const openRequestIds = openInsuranceRequests?.flatMap((request) => {
    return request?.requestId ? [Number(request.requestId)] : [];
  });

  const insuranceRequestsCount = openInsuranceRequests?.length ?? 0;
  return (
    <Box p={3}>
      <Box pb={3}>
        <Grid
          container
          rowGap={1.5}
          columns={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2}
            display="flex"
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignSelf="center"
          >
            <Tabs value={0}>
              <Tab
                value={0}
                icon={
                  <Stack direction={"row"} spacing={0.5}>
                    <Typography
                      fontWeight={700}
                      variant="h6"
                      textTransform={"initial"}
                    >
                      {fleetName}
                    </Typography>
                  </Stack>
                }
                label={
                  <Box width={"100%"} mb={1}>
                    <Typography
                      variant="body1"
                      textTransform={"initial"}
                      textAlign={"start"}
                    >
                      {insuranceRequestsCount} Order
                      {insuranceRequestsCount !== 1 ? "s" : ""}
                    </Typography>
                  </Box>
                }
              />
            </Tabs>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            display={"flex"}
            justifyContent="center"
            alignSelf={"center"}
            mb={2}
          >
            <SearchBar
              searchTerm={insuranceRequestSearch.search}
              onChange={(searchTerm) =>
                updateInsuranceRequestsSearch({ search: searchTerm })
              }
              placeholder="Search Orders"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={1.5}
            lg={2}
            display={"flex"}
            justifyContent={{ xs: "center", md: "flex-end" }}
            mb={2}
          >
            <Stack
              direction={"row"}
              spacing={1}
              display={"flex"}
              alignItems={"center"}
            >
              {fleet && (
                <AddClaimLink
                  fleetId={fleet?.id}
                  label={"New Order"}
                  theme={theme}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ borderColor: Colors.Grey, mt: { md: -3, xs: -2 } }} />
      {error && (
        <Box mt={10}>
          <Stack spacing={1}>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography
                variant="h6"
                textAlign={"center"}
                color={theme.palette.primary.main}
              >
                We were unable to retrieve your orders
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography
                variant="body1"
                textAlign={"center"}
                color={theme.palette.warning.main}
              >
                Please&nbsp;
                <ButtonBase
                  onClick={() => router.reload()}
                  sx={{ px: 0.2, mt: -0.4 }}
                >
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    variant="body1"
                    textAlign={"center"}
                    color={theme.palette.warning.main}
                  >
                    refresh
                  </Typography>
                </ButtonBase>
                &nbsp;the page or logout and log back in.
              </Typography>
            </Box>
          </Stack>
          <Box mt={10} display={"flex"} justifyContent={"center"}>
            <NeedHelpContactSupportText />
          </Box>
        </Box>
      )}
      {loading && (
        <Box mt={10}>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              variant="h6"
              textAlign={"center"}
              color={theme.palette.primary.main}
            >
              Loading your orders...
            </Typography>
          </Box>
        </Box>
      )}
      {insuranceRequestsCount === 0 && !loading && !error && (
        <Box
          width={"100%"}
          mt={10}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={1}
        >
          <Typography variant="h6" color={theme.palette.primary.main}>
            You have no orders
          </Typography>
          {fleet && (
            <AddClaimLink
              fleetId={fleet?.id}
              label={"Add an Order"}
              theme={theme}
            />
          )}
        </Box>
      )}
      {!loading && fleet?.id && insuranceRequestsCount > 0 && (
        <Box mb={4} mt={5}>
          <Typography variant="h6">Orders</Typography>
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 16px",
            }}
          >
            <TableHeadWithSort
              headers={headers}
              sortDirection={sortDirection}
              sortField={sortField ?? DEFAULT_ORDER_FIELD}
              onSortClick={updateSort}
              cellSx={{ border: "none" }}
              textSx={{ fontWeight: "bold" }}
            />
            <TableBody>
              <UnreadRequestsProvider initialRequestIds={openRequestIds}>
                {openInsuranceRequests?.map((claim) => {
                  if (!claim) return null;
                  return (
                    <InsuranceRequestTableRow
                      key={`claim-row-${claim.id}`}
                      insuranceRequest={claim}
                      fleetId={fleet?.id}
                    />
                  );
                })}
              </UnreadRequestsProvider>
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default InsuranceRequests;
