import { Dispatch, useEffect, useState } from "react";

import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";

import CollapsibleTable from "shared/components/CollapsibleTable/CollapsibleTable";
import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import {
  CollapsibleTableHeader,
  SortOrderBy,
} from "shared/components/CollapsibleTable/types";
import {
  createLabel,
  createTableColumn,
  createTableHeader,
  generateOrderBy,
} from "shared/components/CollapsibleTable/utils";
import DashboardHeader from "shared/components/DashboardHeader/DashboardHeader";
import StyledLink from "shared/components/StyledLink";
import useRouterSort from "shared/components/table/TableHeadWithSort/useRouterSort";
import TextWithTooltip from "shared/components/tooltips/TextWithTooltip";
import VehicleImage, {
  VehicleImageHeight,
  VehicleImageWidth,
} from "shared/components/vehicles/VehicleImage";
import {
  GetOpenRequestsQuery,
  Order_By,
  useGetOpenRequestsQuery,
  UsersFleetVehicles_Bool_Exp,
} from "shared/generated/graphql";
import { prettyMonthDayYear } from "shared/lib/dates";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../backend/common/workflow.types";
import NeedHelpContactSupportText from "../../components/NeedHelpContactSupportText";
import { clearServiceStorage } from "../../providers/AccountSettings/storageUtils";
import { useFleets } from "../../providers/FleetProvider";
import { useServices } from "../../providers/ServiceFlowProvider";
import {
  ctaStatuses,
  RequestStatusDisplayVehicleText,
  SaasRequestStatusDisplayVehicleText,
} from "../../services/request.service";
import useFleetSearch from "../fleets/useFleetSearch";
import Tags from "../tags";
import ToggleTagsButton from "../tags/ToggleTagsButton";

type RequestTableType = GetOpenRequestsQuery["requests"][0];

const ResponsiveOrderTable = ({
  requests,
  updateSort,
  loading,
  sortField,
  sortDirection,
  selectedFleetTags,
  setSelectedFleetTags,
}: {
  updateSort: (sortField?: string, sortDirection?: Order_By) => void;
  loading: boolean;
  sortField: string | undefined;
  requests: RequestTableType[] | undefined;
  sortDirection: Order_By;
  selectedFleetTags: never[];
  setSelectedFleetTags: Dispatch<React.SetStateAction<never[]>>;
}) => {
  const [showTags, setShowTags] = useState(false);
  const { getUnreadMessagesCount, setRequestIds } = useUnreadRequests();
  const { currentFleetId } = useFleets();
  const [orderBy, setOrderBy] = useState<SortOrderBy>({
    field: sortField ?? "id",
    direction: sortDirection || Order_By.Desc,
  });

  useEffect(() => {
    setRequestIds(requests?.map((r) => r.id) ?? []);
  }, [requests, setRequestIds]);

  const sortBy = (sortOrder: SortOrderBy) => {
    updateSort(sortOrder.field, sortOrder.direction);
    setOrderBy(sortOrder);
  };

  const cellContent = (request: RequestTableType) => {
    const isSU360 = request?.workflowType === WorkflowType.ServiceUp;
    return {
      vehicleImage: (
        <VehicleImage
          imageUrl={request.usersFleetVehicles?.imageUrl}
          widthUsage={VehicleImageWidth.thumbnail}
          heightUsage={VehicleImageHeight.thumbnail}
          sx={{ transform: "scaleX(-1)" }}
        />
      ),
      order: (
        <UnreadMessageBadge
          content={
            <StyledLink
              href={`request/${request?.id}?fleetId=${currentFleetId}`}
            >
              {request?.id}
            </StyledLink>
          }
          unreadMessageCount={getUnreadMessagesCount(Number(request.id))}
        />
      ),
      shop: (
        <TextWithTooltip
          text={isSU360 ? "ServiceUp" : request?.shop?.name || "--"}
          variant="body2"
        />
      ),
      fleet: (
        <TextWithTooltip text={request?.fleet?.name ?? "--"} variant="body2" />
      ),
      vehicleName: (
        <TextWithTooltip
          text={request?.usersFleetVehicles?.carNickname ?? "--"}
          variant="body2"
        />
      ),
      vehicle: (
        <TextWithTooltip
          text={`${request.usersFleetVehicles?.vehicle?.year} ${request.usersFleetVehicles?.vehicle?.make} ${request.usersFleetVehicles?.vehicle?.model}`}
          variant="body2"
        />
      ),
      licensePlate: (
        <TextWithTooltip
          text={request.usersFleetVehicles?.vehicle?.plate ?? "--"}
          variant="body2"
        />
      ),
      vin: (
        <TextWithTooltip
          text={request.usersFleetVehicles?.vehicle?.vin ?? "--"}
          variant="body2"
        />
      ),
      createdAt: (
        <Typography variant="body2">
          {prettyMonthDayYear(request?.createdAt)}
        </Typography>
      ),
      customerECD: (
        <Typography variant="body2">
          {prettyMonthDayYear(request?.customerECD, true) || "--"}
        </Typography>
      ),
      status: ctaStatuses?.includes(request?.status) ? (
        <Button
          variant="contained"
          size="small"
          href={`request/${request?.id}?fleetId=${currentFleetId}`}
        >
          <TextWithTooltip
            text={
              isSU360
                ? RequestStatusDisplayVehicleText.get(request?.status ?? "")
                : SaasRequestStatusDisplayVehicleText.get(request?.status ?? "")
            }
            variant="inherit"
          />
        </Button>
      ) : (
        <Chip
          variant="outlined"
          size="small"
          label={
            isSU360
              ? RequestStatusDisplayVehicleText.get(request?.status ?? "")
              : SaasRequestStatusDisplayVehicleText.get(request?.status ?? "")
          }
          sx={{
            maxWidth: { xs: "160px", sm: "100%" },
          }}
        />
      ),
      poNumber: (
        <TextWithTooltip text={request.poNumber ?? "--"} variant="body2" />
      ),
    };
  };
  const labels = {
    image: createLabel("image", ""),
    order: createLabel("id", "Order"),
    shop: createLabel("shop.name", "Shop"),
    fleet: createLabel("fleet.name", "Fleet"),
    vehicleName: createLabel("usersFleetVehicles.carNickname", "Name"),
    vehicle: createLabel("vehicle.year", "YMM"),
    licensePlate: createLabel("vehicle.plate", "License Plate"),
    vin: createLabel("vehicle.vin", "VIN"),
    status: createLabel("status", "Status"),
    requestedDate: createLabel("createdAt", "Requested Date"),
    customerECD: createLabel("customerECD", "ECD"),
    poNumber: createLabel("poNumber", "PO#"),
  };
  const desktopHeaders: CollapsibleTableHeader[] = [
    createTableHeader(labels.image.name, labels.image.label),
    createTableHeader(labels.order.name, labels.order.label, true),
    createTableHeader(labels.shop.name, labels.shop.label, true),
    createTableHeader(labels.fleet.name, labels.fleet.label, true),
    createTableHeader(labels.vehicleName.name, labels.vehicleName.label, true),
    createTableHeader(labels.vehicle.name, labels.vehicle.label, true),
    createTableHeader(
      labels.licensePlate.name,
      labels.licensePlate.label,
      true
    ),
    createTableHeader(labels.vin.name, labels.vin.label, true),
    createTableHeader(labels.status.name, labels.status.label, true),
  ];
  const desktopColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.shop.name, cellContent(request).shop),
    createTableColumn(labels.fleet.name, cellContent(request).fleet),
    createTableColumn(
      labels.vehicleName.name,
      cellContent(request).vehicleName
    ),
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.vin.name, cellContent(request).vin),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];
  const desktopSubHeaders: CollapsibleTableHeader[] = [
    createTableHeader(labels.poNumber.name, labels.poNumber.label, true),
    createTableHeader(
      labels.requestedDate.name,
      labels.requestedDate.label,
      true
    ),
    createTableHeader(labels.customerECD.name, labels.customerECD.label, true),
  ];
  const desktopSubColumns = (request: RequestTableType) => [
    createTableColumn(labels.poNumber.name, cellContent(request).poNumber),
    createTableColumn(
      labels.requestedDate.name,
      cellContent(request).createdAt
    ),
    createTableColumn(
      labels.customerECD.name,
      cellContent(request).customerECD
    ),
  ];
  const tabletHeaders = [
    createTableHeader(labels.image.name, labels.image.label),
    createTableHeader(labels.order.name, labels.order.label, true),
    createTableHeader(labels.shop.name, labels.shop.label, true),
    createTableHeader(labels.vehicleName.name, labels.vehicleName.label, true),
    createTableHeader(labels.status.name, labels.status.label, true),
  ];
  const tabletColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.shop.name, cellContent(request).shop),
    createTableColumn(
      labels.vehicleName.name,
      cellContent(request).vehicleName
    ),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];
  const tabletSubHeaders: CollapsibleTableHeader[] = [
    createTableHeader(labels.fleet.name, labels.fleet.label, true),
    createTableHeader(labels.vehicle.name, labels.vehicle.label, true),
    createTableHeader(
      labels.licensePlate.name,
      labels.licensePlate.label,
      true
    ),
    createTableHeader(labels.vin.name, labels.vin.label, true),
    createTableHeader(
      labels.requestedDate.name,
      labels.requestedDate.label,
      true
    ),
    createTableHeader(labels.customerECD.name, labels.customerECD.label, true),
  ];
  const tabletSubColumns = (request: RequestTableType) => [
    createTableColumn(labels.fleet.name, cellContent(request).fleet),
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.vin.name, cellContent(request).vin),
    createTableColumn(
      labels.requestedDate.name,
      cellContent(request).createdAt
    ),
    createTableColumn(
      labels.customerECD.name,
      cellContent(request).customerECD
    ),
  ];
  const mobileColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];
  const mobileSubHeaders = [
    createTableHeader(labels.shop.name, labels.shop.label),
    createTableHeader(labels.fleet.name, labels.fleet.label),
    createTableHeader(labels.vehicleName.name, labels.vehicleName.label),
    createTableHeader(labels.vehicle.name, labels.vehicle.label),
    createTableHeader(labels.licensePlate.name, labels.licensePlate.label),
    createTableHeader(labels.vin.name, labels.vin.label),
    createTableHeader(labels.poNumber.name, labels.poNumber.label),
    createTableHeader(labels.requestedDate.name, labels.requestedDate.label),
    createTableHeader(labels.customerECD.name, labels.customerECD.label),
  ];
  const mobileSubColumns = (request: RequestTableType) => [
    createTableColumn(labels.shop.name, cellContent(request).shop),
    createTableColumn(labels.fleet.name, cellContent(request).fleet),
    createTableColumn(
      labels.vehicleName.name,
      cellContent(request).vehicleName
    ),
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.vin.name, cellContent(request).vin),
    createTableColumn(labels.poNumber.name, cellContent(request).poNumber),
    createTableColumn(
      labels.requestedDate.name,
      cellContent(request).createdAt
    ),
    createTableColumn(
      labels.customerECD.name,
      cellContent(request).customerECD
    ),
  ];

  return (
    <Box mb={4}>
      <CollapsibleTable
        items={requests}
        loading={loading}
        desktopData={{
          headers: desktopHeaders,
          columns: desktopColumns,
          subHeaders: desktopSubHeaders,
          subColumns: desktopSubColumns,
        }}
        tabletData={{
          headers: tabletHeaders,
          columns: tabletColumns,
          subHeaders: tabletSubHeaders,
          subColumns: tabletSubColumns,
        }}
        mobileData={{
          columns: mobileColumns,
          subHeaders: mobileSubHeaders,
          subColumns: mobileSubColumns,
        }}
        orderBy={orderBy}
        setOrderBy={sortBy}
        firstAction={
          <ToggleTagsButton showTags={showTags} setShowTags={setShowTags} />
        }
        firstActionResult={
          <>
            {showTags && (
              <Tags
                selectedFleetTags={selectedFleetTags}
                setSelectedFleetTags={setSelectedFleetTags}
                showCount={false}
              />
            )}
          </>
        }
      />
    </Box>
  );
};

export const OrdersPage = () => {
  const router = useRouter();
  const { currentFleetIds } = useFleets();
  const { clearServices } = useServices();
  const { fleetSearch, updateFleetSearch } = useFleetSearch();
  const [selectedFleetTags, setSelectedFleetTags] = useState([]);

  useEffect(() => {
    setSelectedFleetTags([]);
  }, [currentFleetIds]);

  useEffect(() => {
    clearServices();
    clearServiceStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sortField, sortDirection, updateSort } = useRouterSort({
    defaultField: "id",
  });

  const { data, loading, error } = useGetOpenRequestsQuery({
    skip: !currentFleetIds,
    fetchPolicy: "network-only",
    variables: {
      fleetIds: currentFleetIds,
      orderBy: generateOrderBy(sortField, sortDirection),
      filter: getSearchFilters(
        fleetSearch.search,
        currentFleetIds ?? [0],
        selectedFleetTags
      ),
    },
  });

  const openRequests = data?.requests.filter((r) => r.usersFleetVehicles);

  return (
    <Box p={3}>
      <Divider sx={{ borderColor: "divider" }} />
      <Box mb={4} mt={2}>
        <DashboardHeader
          title="Orders"
          searchTerm={fleetSearch.search}
          updateSearchTerm={(searchTerm) => {
            updateFleetSearch({ search: searchTerm });
          }}
        />
        <Box mb={4}>
          <ResponsiveOrderTable
            requests={openRequests}
            updateSort={updateSort}
            loading={loading}
            sortField={sortField}
            sortDirection={sortDirection}
            selectedFleetTags={selectedFleetTags}
            setSelectedFleetTags={setSelectedFleetTags}
          />
        </Box>
        {error && (
          <Box mt={10}>
            <Stack spacing={1}>
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="h3" textAlign={"center"}>
                  We were unable to retrieve your requests
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="body1" textAlign={"center"}>
                  Please&nbsp;
                  <ButtonBase
                    onClick={() => router.reload()}
                    sx={{ px: 0.2, mt: -0.4 }}
                  >
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      variant="body1"
                      textAlign={"center"}
                    >
                      refresh
                    </Typography>
                  </ButtonBase>
                  &nbsp;the page or logout and log back in.
                </Typography>
              </Box>
            </Stack>
            <Box mt={10} display={"flex"} justifyContent={"center"}>
              <NeedHelpContactSupportText />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

function getSearchFilters(
  search: string,
  fleetIds: number[],
  selectedFleetTags: number[]
) {
  const vehicleTags =
    selectedFleetTags.length > 0
      ? [{ vehicleTags: { tagId: { _in: selectedFleetTags } } }]
      : [];

  const orData: UsersFleetVehicles_Bool_Exp[] = [
    {
      carNickname: { _ilike: `%${search}%` },
    },
    {
      year: { _ilike: `%${search}%` },
    },
    {
      make: { _ilike: `%${search}%` },
    },
    {
      model: { _ilike: `%${search}%` },
    },
    {
      plate: { _ilike: `%${search}%` },
    },
    {
      vin: { _ilike: `%${search}%` },
    },
    {
      requests: {
        services: {
          description: { _ilike: `%${search}%` },
        },
      },
    },
    {
      requests: {
        poNumber: { _ilike: `%${search}%` },
      },
    },
  ];

  if (search && !isNaN(+search)) {
    orData.push({
      requests: {
        id: { _eq: parseInt(search) },
      },
    });
  }
  return {
    fleetId: fleetIds.length > 1 ? { _in: fleetIds } : { _eq: fleetIds[0] },
    _and: [
      {
        deletedAt: { _is_null: true },
      },
      {
        _or: orData,
      },
      ...vehicleTags,
    ],
  };
}
